<template>
  <div>
    <div class="seat-header-row">
      <div v-for="item in HEADERS_ROW">
        <p class="header-row-item">{{ item }}
        </p>
      </div>
    </div>
    <div style="display: flex;flex-direction: row">
      <div class="seat-header-column">
        <div v-for="item in HEADERS_COLUMN">
          <p class="header-column-item">{{ item }}
          </p>
        </div>
      </div>
      <div style="display: flex;flex-direction: row">
        <div v-for="row_item in seats">
          <div v-for="column_item in row_item">
            <p class="seat-item">
              {{column_item.item_row }}    {{column_item.item_column }}
            </p>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "SelectSeat",
  data() {
    return {
      HEADERS_ROW: [
        'A', 'B', 'C', 'D', 'E'
      ],
      HEADERS_COLUMN: [
        '1', '2', '3', '4', '5'
      ],
      seats: []
    }
  },
  mounted() {
    for (var i = 0; i < 5; i++) {
      var row = [];
      for (var j = 0; j < 5; j++) {
        var item = {
          item_row: i+1,
          item_column: this.HEADERS_ROW[j]
        }
        row.push(item)
      }
      this.seats.push(row)
    }
    console.log('this.seats ' + JSON.stringify(this.seats))
  },
  methods: {}
}
</script>

<style scoped>

.seat-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 46, 46, 0.24);;
  border-radius: 24px;
  height: 48px;
}

.seat-header-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 46, 46, 0.24);;
  border-radius: 24px;
  width: 48px;
}

.header-row-item {
  width: 140px;
  text-align: center;
  font-weight: 600;
  color: white;
}

.header-column-item {
  height: 120px;
  line-height: 120px;
  font-weight: 600;
  color: white;
}
.seat-item {
  width: 140px;
  height: 120px;
  line-height: 140px;
  text-align: center;
  background: rgba(255, 255, 255, 0.31);
  border: 4px solid #FFEED5;
}

</style>